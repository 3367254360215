import React, { useEffect } from 'react';
import './App.css';
import logo from './assets/images/logo.png';
import NumberFormat from 'react-number-format';
import { Grid, Button } from '@material-ui/core';
import SelectAmountDialog from './SelectAmount';
import { round } from 'lodash';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { IOffering } from './types';

function App() {
  const [open, setOpen] = React.useState(false);
  const [selectedOffering, setSelectedOffering] = React.useState<IOffering>({
    id: 'collecte',
    description: 'Selecteer het bedrag dat u wilt doneren en volg de betaalinstructies op het volgende scherm.',
    amounts: [25, 10, 5],
    allowCustomAmount: true,
  });
  const [availableOfferings, setAvailableOfferings] = React.useState<IOffering[]>();
  const [selectedAmount, setSelectedAmount] = React.useState<number | undefined>();

  // Fetch offerings
  //
  useEffect(() => {
    axios.get<IOffering[]>('https://gku-api.gku-pkn.nl/offerings').then((response) => {
      setAvailableOfferings(response.data);
    });
  }, []);

  // Open handler for custom amount selector
  //
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close handler for custom amount selector
  //
  const handleClose = (value: number) => {
    setOpen(false);
    setSelectedAmount(value);
  };

  // Check if a target is provided and if its part of the available
  // offerings. If so, select that offering here
  //
  React.useEffect(() => {
    const pathName = document.location.pathname.replace(/\//g, '');
    if (pathName && availableOfferings?.length) {
      const match = availableOfferings.find((item) => item.id === pathName);
      if (match) {
        setSelectedOffering(match);
      }
    }
  }, [availableOfferings]);

  // If amount is selected redirect to payment screen
  //
  React.useEffect( () => {
    if (selectedAmount) {
      document.location.href = `https://gku-api2.gku-pkn.nl/offerings/new/${round(selectedAmount * 100)}/${selectedOffering.id}`
      // console.log("Amount selected", selectedAmount);
    }
  }, [selectedAmount, selectedOffering]);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Helmet>
        <title>GKU PKN collecte app</title>
        <meta name="description" content={selectedOffering.description} />
      </Helmet>
      <Grid item className="header">
        <img className="logo" alt="logo" src={logo} />
      </Grid>
      <Grid item>
        <p>{selectedOffering.description}</p>
      </Grid>
      {selectedOffering.amounts.map((amount) => (
        <Grid item key={amount}>
          <Button className="button" variant="contained" color="primary" onClick={() => setSelectedAmount(amount)}>Doneer&nbsp;
          <NumberFormat value={amount} displayType="text" decimalSeparator="," prefix='€'/>
          </Button>
        </Grid>
      ))}
      {selectedOffering.allowCustomAmount && (
        <>
          <Grid item>
            <Button className="button" variant="contained" color="primary" onClick={handleClickOpen}>Selecteer ander bedrag</Button>
          </Grid>
          <SelectAmountDialog selectedValue={selectedAmount} open={open} onClose={handleClose} />
        </>
      )}
    </Grid>
  );
}

export default App;
