import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
} from "@material-ui/core";
import React from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { isEmpty } from 'lodash';

export interface SelectAmountDialogProps {
  open: boolean;
  selectedValue: number | undefined;
  onClose: (value: number) => void;
}

const schema = Yup.object().shape({
  amount: Yup.number()
    .min(2.5, 'Minimum bedrag is 2.5')
    .max(500, 'Maximum bedrag is 500')
    .required('Required'),
});

function SelectAmountDialog(props: SelectAmountDialogProps) {
  const { onClose, selectedValue, open } = props;
  const handleClose = () => {
    onClose(selectedValue as number);
  };

  const selectedAmount = (value: number) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="selecteer bedrag" open={open}>
      <DialogTitle id="simple-dialog-title">Selecteer een bedrag</DialogTitle>
      <div className="amount-input">
      <Formik
        initialValues={{
          amount: 2.5,
        }}
        validationSchema={schema}
        onSubmit={values => {
          selectedAmount(values.amount);
        }}
      >
      {({ errors }) => (
        <Form>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Field
                name="amount"
                label="Bedrag"
                type="number"
                inputProps={{ min: "2.5", max: "500", step: "0.1" }}
                component={TextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" disabled={!isEmpty(errors.amount)} color="primary">Selecteer</Button>
            </Grid>
          </Grid>
        </Form>
      )}
      </Formik>
      </div>
    </Dialog>
  );
}

export default SelectAmountDialog;